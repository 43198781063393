import { z, ZodFormattedError } from "zod";
import { Form, useActionData, useLoaderData } from "@remix-run/react";
import { ErrorMessage, Field, Fieldset, Label } from "~/components/catalyst/fieldset";
import { Input } from "~/components/catalyst/input";
import { ActionArgsWithContext, json, LoaderArgsWithContext, redirect, TypedResponse } from "@remix-run/node";
import TopLevelFormError from "~/components/top-level-form-error";
import LoadingSpinner from "~/components/loading-spinner";
import { InitAnonymousIntercom } from "~/Intercom";

const formSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1, "Password cannot be blank")
});

const redirectUrl = `/app/dashboard`;

export async function loader({ request, context }: LoaderArgsWithContext) {
  if (await context.authenticationService.isUserLoggedIn(request)) {
    return redirect(redirectUrl);
  }

  const whiteLabelSiteLogoUrls = context.requestScopedData.whiteLabelSiteViewModel!.logoUrls
  return json({whiteLabelSiteLogoUrls});
}


export type LoginForm = typeof formSchema._type

export interface LoginActionResponse {
  authAttempted: boolean,
  validationError: ZodFormattedError<LoginForm> | null
}

export async function action({
                               request, context
                             }: ActionArgsWithContext): Promise<TypedResponse<LoginActionResponse>> {

  const formPayload = Object.fromEntries(await request.clone().formData());

  const parsed = formSchema.safeParse(formPayload);

  // If the validation fails, return the errors to the client
  if (!parsed.success) {
    return json({ authAttempted: false, validationError: parsed.error.format() });
  }

  const { error } = await context.authenticationService.loginWithEmailPassword(request);

  if (error) {
    return json({
      validationError: null,
      authAttempted: true
    });
  }

  throw new Error("this should be unreachable; should have redirected on successful login or returned error already")
}

export default function LoginPage() {
  const data = useActionData<typeof action>();
  const loaderData = useLoaderData<typeof loader>();
  const loginFailure = data?.authAttempted;

  return (
    <>
      <LoadingSpinner></LoadingSpinner>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {loaderData.whiteLabelSiteLogoUrls.logoUrl ? <img
            className="mx-auto h-20 w-auto"
            src={loaderData.whiteLabelSiteLogoUrls.logoUrl}
            alt="logo"
          /> : <div className="mx-auto h-20 w-auto"></div>}
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Form className="space-y-6" action="#" method="POST">
            <Fieldset>
              {loginFailure &&
                <TopLevelFormError>These credentials do not match our records.</TopLevelFormError>}
              <Field>
                <Label>Email</Label>
                <Input name="email" type="email" autoComplete="email"
                       invalid={!!(data && data?.validationError?.email) || loginFailure} />
                {data && data?.validationError?.email &&
                  <ErrorMessage>{data.validationError.email._errors[0]}</ErrorMessage>}
              </Field>
              <Field>
                <div className="flex items-center justify-between">
                  <Label>Password</Label>
                  <div className="text-sm">
                    <a href="/forgot-password" className="font-semibold text-indigo-400 hover:text-indigo-300">
                      Forgot password?
                    </a>
                  </div>
                </div>
                <Input name="password" type="password" autoComplete="current-password"
                       invalid={!!(data && data?.validationError?.password) || loginFailure} />
                {data && data?.validationError?.password &&
                  <ErrorMessage>{data?.validationError?.password._errors[0]}</ErrorMessage>}
              </Field>
            </Fieldset>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </Form>

          <p className="mt-10 text-center text-sm text-gray-400">
            Not a member?{" "}
            <a href="/sign-up" className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
              Sign Up
            </a>
          </p>
        </div>
      </div>
      <InitAnonymousIntercom />

    </>
  );
}
